/* Terminal.css */
.flashing-text {
    animation: flash 4s step-end infinite;
}

@keyframes flash {
    0%, 75% {
        opacity: 1;
    }
    76%, 100% {
        opacity: 0;
    }
}
  .terminal {
    background-color: #000;
    color: #fff;
    font-family: monospace;
    padding: 20px;
    margin: 0;
    font-size: 1.5em;
    white-space: pre;
    overflow: auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }



.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}